import HeaderBar from "../HeaderBar";
import "./index.css";

export interface PageProps {
  name: string;
  isAuthenticating?: boolean;
  hideUserMenu?: boolean;
  children?: React.ReactNode;
}

const Page = (props: PageProps) => {
  const { name, isAuthenticating, hideUserMenu, children } = props;
  return (
    <div className="page-container">
      <div className="navbar-container">
        <HeaderBar name={name} hideUserMenu={hideUserMenu || isAuthenticating} />
      </div>
      <div className="child-container">{children}</div>
    </div>
  );
};

export default Page;
