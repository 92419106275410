import { useNavigate } from "react-router-dom";
import MUIDataTable, { FilterType, Responsive, SelectableRows } from "mui-datatables";
import { Button } from "@mui/material";
import { formatTime } from "../../utils";
import { DisplayablePlantingData } from "../../config";
import "./index.css";

const CONFIG_STORAGE_KEY = "active-planting";

interface LiveViewListProps {
  dataLoaded: boolean;
  activePlantings?: DisplayablePlantingData[];
}

export default function LiveViewList(props: LiveViewListProps) {
  const { dataLoaded, activePlantings } = props;
  const navigate = useNavigate();
  const data = (dataLoaded && activePlantings) || [];

  const infoOptions = {
    setCellProps: () => ({
      align: "center",
    }),
  };

  const viewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any) => (
      <Button variant="contained" onClick={() => navigate(`/live/${value}`)}>
        Live
      </Button>
    ),
  };

  const dateOptions = {
    customBodyRender: (value: any) => formatTime(value),
    setCellProps: () => ({
      align: "center",
    }),
  };

  let tableConfig: any = null;
  try {
    tableConfig = JSON.parse(localStorage.getItem(CONFIG_STORAGE_KEY) || "");
  } catch (e) {}

  const COLUMNS = [
    { label: " ", name: "systemId", options: { ...viewOptions, ...tableConfig?.columns[0] } },
    { label: "Company", name: "companyName", options: { ...infoOptions, display: false, ...tableConfig?.columns[1] } },
    { label: "System", name: "systemName", options: { ...infoOptions, ...tableConfig?.columns[2] } },
    { label: "Farm", name: "farm", options: { ...infoOptions, ...tableConfig?.columns[3] } },
    { label: "Field", name: "field", options: { ...infoOptions, ...tableConfig?.columns[4] } },
    { label: "Variety", name: "variety", options: { ...infoOptions, ...tableConfig?.columns[5] } },
    { label: "Seedlot", name: "seedlot", options: { ...infoOptions, ...tableConfig?.columns[6] } },
    { label: "Acreage", name: "acreage", options: { ...infoOptions, ...tableConfig?.columns[7] } },
    { label: "Seed Spacing", name: "seedSpacing", options: { ...infoOptions, ...tableConfig?.columns[8] } },
    { label: "Seed Weight", name: "seedWeight", options: { ...infoOptions, ...tableConfig?.columns[9] } },
    { label: "Last Updated", name: "updateTime", options: { ...dateOptions, ...tableConfig?.columns[10] } },
  ];

  const OPTIONS = {
    download: false,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    responsive: "vertical" as Responsive,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: CONFIG_STORAGE_KEY,
    textLabels: {
      body: {
        noMatch: dataLoaded ? "No active plantings available." : "Loading ...",
      },
    },
  };

  return (
    <div className="field-log-table">
      <MUIDataTable title={"Active Plantings"} data={data} columns={COLUMNS} options={OPTIONS} />
    </div>
  );
}
