const SERVER_HOST = process.env.REACT_APP_API;
const SERVER_SCHEME = SERVER_HOST?.startsWith("localhost") ? "http" : "https";
const SERVER_URL = `${SERVER_SCHEME}://${SERVER_HOST}`;
const API_URL = `${SERVER_URL}/nxt/system`;

const PLANTING = `${API_URL}/planting`;
const PLANTINGS = `${API_URL}/plantings`;
const OPENPLANTINGS = `${PLANTINGS}/open`;
const CLOSEDPLANTINGS = `${PLANTINGS}/closed`;
const ACTIVEPLANTING = `${PLANTINGS}/active`;

const STATUS = `System/Status`;
const LIVECOUNTS = `nxt/Sensors/LiveCounts`;
const PLANTINGCOUNTS = `Planting/Counts`;

const API_FAIL_ERROR = "Could not fetch data from server";

const SYSTEMS = `${SERVER_URL}/systems`;
const getSystemIdURL = (systemId: string) => `${SERVER_URL}/system/${systemId}`;
const getPlantingInfoURL = (systemId: string, plantingId: number) =>
  `${getSystemIdURL(systemId)}/${PLANTINGCOUNTS}/${plantingId}`;
const getSystemStatusURL = (systemId: string) => `${getSystemIdURL(systemId)}/${STATUS}`;
const getLiveCountsURL = (systemId: string) => `${getSystemIdURL(systemId)}/${LIVECOUNTS}`;

const config = {
  s3: {
    REGION: "ca-central-1",
    BUCKET: "config-bucket-325591102945-ca-central-1",
  },
  apiGateway: {
    REGION: "ca-central-1",
    URL: process.env.REACT_APP_API,
  },
  cognito: {
    REGION: "ca-central-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: "None",
  },
  api: {
    SYSTEMS,
    PLANTING,
    OPENPLANTINGS,
    CLOSEDPLANTINGS,
    ACTIVEPLANTING,
    PLANTINGCOUNTS,
    API_FAIL_ERROR,
    STATUS,
    LIVECOUNTS,
    getPlantingInfoURL,
    getSystemStatusURL,
    getLiveCountsURL,
  },
};

export enum PlantingType {
  Active,
  Open,
  Closed,
}

export const PLANTING_TYPE_NAMES = {
  [PlantingType.Active]: "Active",
  [PlantingType.Open]: "Open",
  [PlantingType.Closed]: "Closed",
};

export interface ApiPlantingData {
  id: number;
  companyName: string;
  farmName: string;
  fieldName: string;
  varietyName: string;
  seedlotName: string;
  range: number;
  acreage: number;
  space: number;
  seedWeight: number;
  updateTime: string;
  apUpdateTime?: string; // active plantings only
}

export interface PlantingData extends ApiPlantingData {
  type: PlantingType;
}

export interface DisplayablePlantingData {
  systemId: string;
  systemAndPlantingId: string;
  id: number;
  companyName: string;
  systemName: string;
  type: string;
  farm: string;
  field: string;
  variety: string;
  seedlot: string;
  range: number;
  acreage: number;
  seedSpacing: number;
  seedWeight: number;
  updateTime: string;
}

export interface SystemStatusData {
  seeding: boolean;
  lowSpeed: boolean;
  currentSpeedMph: number;
  averageSpacingInches: number;
  acreage: number;
  targetSpacing: number;
  cWeightPerAcre: number;
  totalCWeight: number;
  tpvMode: number;
}

export interface WheelLiveCountsMessage {
  message: string;
}

export interface WheelLiveCountsData {
  status: number;
  wheelNumber: number;
  wheelSpeed: number;
  ideal: number;
  notIdeal: number;
  doubles: number;
  misses: number;
  distance: number;
  outOfRange: boolean;
  wheelPressure: number;
  staticPressureAlarm: boolean;
  lowWheelSpeed: boolean;
  batteryStateOfCharge: number;
  batteryStateOfChargeAlarm: boolean;
  successPercent: number;
  successPercentRow: number;
}

export default config;
