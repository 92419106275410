import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Col, ListGroup, Row } from "react-bootstrap";
import FrontEndContext from "../../context/FrontEndContext";
import NotFound from "../NotFound";
import Page from "../../components/Page";
import PlantingSummaryGraph from "../../components/PlantingSummaryGraph";
import PlantingSummaryTable, { PlantingSummaryData } from "../../components/PlantingSummaryTable";
import { formatTime } from "../../utils";
import "./index.css";
import config from "../../config";
import { ApiPlantingData } from "../../config";

const { PLANTING, getPlantingInfoURL } = config?.api;

interface PlantingCounts {
  ideal: number;
  notIdeal: number;
  doubles: number;
  misses: number;
}

interface WheelPlantingCounts extends PlantingCounts {
  wheel: number;
}

interface PlantingCountsData {
  totals: PlantingCounts;
  wheels: WheelPlantingCounts[];
}

const formatPlantingSummaryData = (plantingCounts: PlantingCountsData): PlantingSummaryData[] => {
  const plantingSummaryData: PlantingSummaryData[] = [];
  const { wheels } = plantingCounts;
  for (let i = 0; i < wheels.length; i++) {
    const wheelCounts: WheelPlantingCounts = wheels[i];
    const { ideal, notIdeal, doubles, misses, wheel } = wheelCounts;
    const successPercentage = (ideal * 100.0) / (ideal + notIdeal + misses + doubles);
    const transformedPoint: PlantingSummaryData = {
      "Wheel Number": wheel || 0,
      Misses: misses,
      Doubles: doubles,
      "Not Ideal": notIdeal,
      Ideal: ideal,
      "Ideal%": successPercentage,
      "% Ideal": successPercentage,
      name: `Row ${wheel || 0}`,
    };
    plantingSummaryData.push(transformedPoint);
  }
  return plantingSummaryData.sort((a, b) => a["Wheel Number"] - b["Wheel Number"]);
};

const HEADER_NAME = "";

const Planting = () => {
  const { systemId, id: paramId } = useParams();
  const id = parseInt(paramId!, 10);
  const { headersWithAuth, isAuthenticating, isAuthenticated } = React.useContext(FrontEndContext);

  const navigate = useNavigate();

  const [plantingDataRequested, setPlantingDataRequested] = useState<boolean>(false);
  const [plantingCounts, setPlantingCounts] = useState<PlantingCountsData | null>(null);
  const [plantingInfo, setPlantingInfo] = useState<ApiPlantingData | null>(null);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (plantingDataRequested) {
      return;
    }

    if (!systemId) {
      return;
    }

    if (!headersWithAuth) {
      return;
    }

    const loadPlantingCountsAndInfo = async () => {
      const params = {
        seedSensingSystemId: systemId,
        plantingId: id,
      };

      const { data: plantingCounts } = await axios.get<PlantingCountsData>(
        getPlantingInfoURL(systemId, id),
        headersWithAuth
      );
      setPlantingCounts(plantingCounts);

      const { data: plantingInfo } = await axios.post<ApiPlantingData>(PLANTING, params, headersWithAuth);
      setPlantingInfo(plantingInfo);
    };

    setPlantingDataRequested(true);
    loadPlantingCountsAndInfo();
  }, [systemId, headersWithAuth, plantingDataRequested, id]);

  if (isAuthenticating || !plantingCounts || !plantingInfo) {
    return (
      <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
        <h1 className="text-center">Loading...</h1>
      </Page>
    );
  }

  if (isNaN(id)) {
    return <NotFound />;
  }

  const { updateTime = new Date().toString() } = plantingInfo || {};
  const formattedUpdateTime = formatTime(updateTime);
  const formattedGraphData = formatPlantingSummaryData(plantingCounts!);

  return (
    <Page name={HEADER_NAME}>
      <div className="planting-container pt-2">
        {" "}
        <div className="notes">
          <h4 className="pb-1">
            <Row className="py-0">
              <Col className={"text-column"} xs={6}>
                Farm: {plantingInfo?.farmName || "-"}
              </Col>
              <Col className={"text-column"} xs={6}>
                Field Name: {plantingInfo?.fieldName || "-"}
              </Col>
            </Row>
            <Row>
              <Col className={"text-column"} xs={6}>
                Variety: {plantingInfo?.varietyName || "-"}
              </Col>
              <Col className={"text-column"} xs={6}>
                Planted Date: {formattedUpdateTime}
              </Col>
            </Row>
          </h4>
          <ListGroup>
            <PlantingSummaryGraph data={formattedGraphData} />
            <div style={{ width: "100%", height: 10 }}> </div>
            <PlantingSummaryTable wheelsInfo={formattedGraphData} />
          </ListGroup>
        </div>
      </div>
    </Page>
  );
};

export default Planting;
