import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable, { FilterType, Responsive, SelectableRows } from "mui-datatables";
import { Button } from "@mui/material";
import { formatTime } from "../../utils";
import { DisplayablePlantingData } from "../../config";
import "./index.css";

const CONFIG_STORAGE_KEY = "all-plantings";

interface FieldLogListProps {
  dataLoaded: boolean;
  activePlantings?: DisplayablePlantingData[];
  openPlantings?: DisplayablePlantingData[];
  closedPlantings?: DisplayablePlantingData[];
}

export default function FieldLogList(props: FieldLogListProps) {
  const { dataLoaded, activePlantings, openPlantings, closedPlantings } = props;
  const navigate = useNavigate();
  const [plantings, setPlantings] = useState<DisplayablePlantingData[]>([]);

  const infoOptions = {
    setCellProps: () => ({
      align: "center",
    }),
  };

  const viewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any) => (
      <Button variant="contained" onClick={() => navigate(`/planting/${value}`)}>
        View
      </Button>
    ),
  };

  const dateOptions = {
    customBodyRender: (value: any) => formatTime(value),
    setCellProps: () => ({
      align: "center",
    }),
  };

  let tableConfig: any = null;
  try {
    tableConfig = JSON.parse(localStorage.getItem(CONFIG_STORAGE_KEY) || "");
  } catch (e) {}

  const COLUMNS = [
    { label: " ", name: "systemAndPlantingId", options: { ...viewOptions, ...tableConfig?.columns[0] } },
    { label: "Company", name: "companyName", options: { ...infoOptions, display: false, ...tableConfig?.columns[1] } },
    { label: "System", name: "systemName", options: { ...infoOptions, ...tableConfig?.columns[2] } },
    { label: "Farm", name: "farm", options: { ...infoOptions, ...tableConfig?.columns[3] } },
    { label: "Field", name: "field", options: { ...infoOptions, ...tableConfig?.columns[4] } },
    { label: "Variety", name: "variety", options: { ...infoOptions, ...tableConfig?.columns[5] } },
    { label: "Seedlot", name: "seedlot", options: { ...infoOptions, ...tableConfig?.columns[6] } },
    { label: "Acreage", name: "acreage", options: { ...infoOptions, ...tableConfig?.columns[7] } },
    { label: "Seed Spacing", name: "seedSpacing", options: { ...infoOptions, ...tableConfig?.columns[8] } },
    { label: "Seed Weight", name: "seedWeight", options: { ...infoOptions, ...tableConfig?.columns[9] } },
    { label: "Last Updated", name: "updateTime", options: { ...dateOptions, ...tableConfig?.columns[10] } },
  ];

  const OPTIONS = {
    download: false,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    responsive: "vertical" as Responsive,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "updateTime",
      direction: "desc" as any,
    },
    storageKey: CONFIG_STORAGE_KEY,
    textLabels: {
      body: {
        noMatch: dataLoaded ? "No field logs available." : "Loading ...",
      },
    },
  };

  useEffect(() => {
    if (!dataLoaded) {
      return;
    }

    const plantings: DisplayablePlantingData[] = [];

    if (activePlantings) {
      for (const planting of activePlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    if (openPlantings) {
      for (const planting of openPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    if (closedPlantings) {
      for (const planting of closedPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    setPlantings(plantings);
  }, [dataLoaded, activePlantings, closedPlantings, openPlantings]);

  return (
    <div className="field-log-table">
      <MUIDataTable title={"Field Logs"} data={plantings} columns={COLUMNS} options={OPTIONS} />
    </div>
  );
}
