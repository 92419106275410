import Page from "../../components/Page";
import "./index.css";
import FrontEndContext from "../../context/FrontEndContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import AuthButton from "../../components/AuthButton";

const NEW_PASSWORD_REQUIRED_CHALLENGE_NAME = "NEW_PASSWORD_REQUIRED";
const HEADER_NAME = "";

export type AuthenticationAction =
  | "Login"
  | "ChangePasswordWithCode"
  | "ChangePassword"
  | "ConfirmationCode"
  | "ForceResetPassword";

interface AuthenticationProps {
  authenticationAction: AuthenticationAction;
}

export default function Authentication(props: AuthenticationProps) {
  const navigate = useNavigate();
  const { authenticationAction: authAction } = props;
  const { handleAuthentication, userName, setUserName } = useContext(FrontEndContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [retypedNewPassword, setRetypedNewPassword] = useState<string>("");

  const currentUserVisible =
    ["Login", "ChangePassword", "ConfirmationCode", "ForceResetPassword"].indexOf(authAction) >= 0;
  const currentUserEnabled = ["Login", "ChangePasswordWithCode", "ConfirmationCode"].indexOf(authAction) >= 0;
  const currentPasswordVisible = ["Login", "ChangePassword"].indexOf(authAction) >= 0;
  const confirmationCodeVisible = ["ChangePasswordWithCode"].indexOf(authAction) >= 0;
  const newPasswordVisible =
    ["ChangePasswordWithCode", "ChangePassword", "ForceResetPassword"].indexOf(authAction) >= 0;
  const retypedPasswordVisible =
    ["ChangePasswordWithCode", "ChangePassword", "ForceResetPassword"].indexOf(authAction) >= 0;
  const forgotPasswordVisible = ["Login"].indexOf(authAction) >= 0;

  // if a user attempts to go to the page without first having logged in they will be redirected
  // to the initial login page.
  if (authAction === "ForceResetPassword" && (userName.length === 0 || oldPassword.length === 0)) {
    navigate("/");
  }

  // Note that the user name is not reset here. If the login forces a password change after a
  // successful login where Cognito has the account forcing a password reset, the user name will
  // remain and be filled into the request confirmation code form.
  const resetInputs = () => {
    setIsLoading(false);
    setConfirmationCode("");
    setOldPassword("");
    setNewPassword("");
    setRetypedNewPassword("");
  };

  const initializeEventHandling = (event: React.SyntheticEvent) => {
    setAuthError("");
    setIsLoading(true);
    event.preventDefault();
  };

  const processErrorAndReset = (error: any) => {
    if (typeof error === "string") {
      setAuthError(error);
      console.log(error);
    } else if (error.message != null) {
      setAuthError(error.message);
      console.log(error.message);
    }

    resetInputs();
  };

  const handleChangePassword = async (event: React.SyntheticEvent) => {
    initializeEventHandling(event);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      navigate("/");
      resetInputs();
    } catch (error: any) {
      processErrorAndReset(error);
    }
  };

  const handleChangePasswordWithCode = async (event: React.SyntheticEvent) => {
    initializeEventHandling(event);
    try {
      await Auth.forgotPasswordSubmit(userName, confirmationCode, newPassword);
      // attempt to login now that the password reset has worked
      await doLogin(userName, newPassword);
      resetInputs();
    } catch (error: any) {
      processErrorAndReset(error);
    }
  };

  const handleConfirmationCode = async (event: React.SyntheticEvent) => {
    initializeEventHandling(event);
    try {
      await Auth.forgotPassword(userName);
      navigate("/change-password-with-code");
      resetInputs();
    } catch (error: any) {
      processErrorAndReset(error);
    }
  };

  const handleForceResetPassword = async (event: React.SyntheticEvent) => {
    initializeEventHandling(event);

    try {
      const user = await Auth.signIn(userName, oldPassword);
      await Auth.completeNewPassword(user, newPassword);
      handleAuthentication(user.getSignInUserSession());
      navigate("/");
      resetInputs();
    } catch (error: any) {
      processErrorAndReset(error);
    }
  };

  const doLogin = async (user: string, pass: string) => {
    try {
      const cognitoUser = await Auth.signIn(user, pass);

      // detect that Cognito requires the user to set a new password after the first correct login
      // and redirect to the force reset password page.
      if (cognitoUser.challengeName === NEW_PASSWORD_REQUIRED_CHALLENGE_NAME) {
        navigate("/force-reset-password");
        setIsLoading(false);
      } else {
        handleAuthentication(cognitoUser.getSignInUserSession());
        navigate("/");
        resetInputs();
      }
    } catch (error: any) {
      handleAuthentication(null, error);
      processErrorAndReset(error);
    }
  };

  const handleLogin = async (event: React.SyntheticEvent) => {
    initializeEventHandling(event);
    await doLogin(userName, oldPassword);
  };

  const handleAuthenticationSubmit =
    authAction === "ChangePassword"
      ? handleChangePassword
      : authAction === "ChangePasswordWithCode"
      ? handleChangePasswordWithCode
      : authAction === "ConfirmationCode"
      ? handleConfirmationCode
      : authAction === "ForceResetPassword"
      ? handleForceResetPassword
      : handleLogin;

  const title =
    authAction === "ChangePassword"
      ? "Change password"
      : authAction === "ChangePasswordWithCode"
      ? "Change password"
      : authAction === "ConfirmationCode"
      ? "Send confirmation code"
      : authAction === "ForceResetPassword"
      ? "Set a new password"
      : "";

  const validateForm =
    authAction === "ChangePassword"
      ? validateChangePasswordForm
      : authAction === "ChangePasswordWithCode"
      ? validateChangePasswordWithCodeForm
      : authAction === "ConfirmationCode"
      ? validateConfirmationCodeForm
      : authAction === "ForceResetPassword"
      ? validateForceResetPasswordForm
      : validateLoginForm;

  const buttonText =
    authAction === "ChangePassword"
      ? "Change Password"
      : authAction === "ChangePasswordWithCode"
      ? "Change Password"
      : authAction === "ConfirmationCode"
      ? "Send Confirmation Code"
      : authAction === "ForceResetPassword"
      ? "Reset Password"
      : "Login";

  function validateLoginForm() {
    return userName.length > 0 && oldPassword.length > 0;
  }

  function validateConfirmationCodeForm() {
    return userName.length > 0;
  }

  function validateChangePasswordWithCodeForm() {
    return confirmationCode.length === 6 && newPasswordCriteriaPasses() && newPassword === retypedNewPassword;
  }

  function validateChangePasswordForm() {
    return oldPassword.length > 0 && newPasswordCriteriaPasses() && newPassword === retypedNewPassword;
  }

  function validateForceResetPasswordForm() {
    return newPasswordCriteriaPasses() && newPassword === retypedNewPassword;
  }

  const newPasswordCriteriaPasses = () =>
    newPasswordCriteria.minLength.passes &&
    newPasswordCriteria.capital.passes &&
    newPasswordCriteria.lower.passes &&
    newPasswordCriteria.number.passes;

  const newPasswordCriteria = {
    minLength: { passes: newPassword.length >= 8, desc: "8 characters" },
    capital: { passes: newPassword.search(/.*[A-Z]/g) >= 0, desc: "one uppercase" },
    lower: { passes: newPassword.search(/.*[a-z]/g) >= 0, desc: "one lowercase" },
    number: { passes: newPassword.search(/\d/g) >= 0, desc: "one digit" },
  };

  const check = String.fromCodePoint(0x02713);
  const ex = String.fromCodePoint(0x02717);

  const newPasswordCriteriaString = (
    <div>
      <small className={`${newPasswordCriteria.minLength.passes ? "text-success" : "text-danger"}`}>
        {newPasswordCriteria.minLength.passes ? check : ex}&nbsp;
        {newPasswordCriteria.minLength.desc}
      </small>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <small className={`${newPasswordCriteria.number.passes ? "text-success" : "text-danger"}`}>
        {newPasswordCriteria.number.passes ? check : ex}&nbsp;
        {newPasswordCriteria.number.desc}
      </small>
      <br />
      <small className={`${newPasswordCriteria.capital.passes ? "text-success" : "text-danger"}`}>
        {newPasswordCriteria.capital.passes ? check : ex}&nbsp;
        {newPasswordCriteria.capital.desc}
      </small>
      &nbsp;&nbsp;
      <small className={`${newPasswordCriteria.lower.passes ? "text-success" : "text-danger"}`}>
        {newPasswordCriteria.lower.passes ? check : ex}&nbsp;
        {newPasswordCriteria.lower.desc}
      </small>
    </div>
  );

  const helpTextRetypedPassword = {
    color: newPassword === retypedNewPassword ? "text-muted" : "text-danger",
    text: newPassword === retypedNewPassword ? "Passwords match" : "Passwords do not match",
    visible: retypedNewPassword.length > 0 ? "visible" : "invisible",
  };

  const handleSetUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleSetConfirmationCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(event.target.value);
  };

  const handleSetPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
  };

  const handleSetNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleRetypedNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRetypedNewPassword(event.target.value);
  };

  return (
    <Page name={HEADER_NAME} hideUserMenu={true}>
      <Alert
        variant="danger"
        onClose={() => {
          setAuthError("");
        }}
        show={authError.length > 0}
        dismissible
      >
        {authError}
      </Alert>

      <div className="authentication">
        <p className="fs-3 mb-4 text-center">{title}</p>
        <Form onSubmit={handleAuthenticationSubmit}>
          {currentUserVisible ? (
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                disabled={!currentUserEnabled}
                value={userName}
                onChange={handleSetUserName}
              />
            </Form.Group>
          ) : null}
          {currentPasswordVisible ? (
            <Form.Group className="mb-3" controlId="old-password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={oldPassword}
                autoComplete="old-password"
                onChange={handleSetPassword}
              />
            </Form.Group>
          ) : null}
          {confirmationCodeVisible ? (
            <Form.Group className="mb-3" controlId="confirmation-code">
              <Form.Label>Confirmation code</Form.Label>
              <Form.Control
                type="text"
                value={confirmationCode}
                autoComplete="off"
                onChange={handleSetConfirmationCode}
              />
            </Form.Group>
          ) : null}
          {newPasswordVisible ? (
            <Form.Group className="mb-3" controlId="new-password">
              <Form.Label>New password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                autoComplete="new-password"
                onChange={handleSetNewPassword}
              />
              {newPasswordCriteriaString}
            </Form.Group>
          ) : null}
          {retypedPasswordVisible ? (
            <Form.Group className="mb-4" controlId="retyped-password">
              <Form.Label>Confirm new password</Form.Label>
              <Form.Control
                type="password"
                value={retypedNewPassword}
                autoComplete="off"
                onChange={handleRetypedNewPassword}
              />
              <small className={`${helpTextRetypedPassword.visible} ${helpTextRetypedPassword.color}`}>
                {helpTextRetypedPassword.text}
              </small>
            </Form.Group>
          ) : null}
          <Container className="mt-4">
            <Row className="pt-2">
              {forgotPasswordVisible ? (
                <Col xs={6} className="px-0">
                  <Button className="px-0 py-0" variant="link" onClick={() => navigate("/confirmation-code")}>
                    Forgot password?
                  </Button>
                </Col>
              ) : null}
              <Col className="px-0">
                <AuthButton
                  className="float-end"
                  size="lg"
                  type="submit"
                  isLoading={isLoading}
                  disable={!validateForm()}
                >
                  {buttonText}
                </AuthButton>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </Page>
  );
}
