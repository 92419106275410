import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import bssiLogo from "../../assets/images/bssi_logo_light.png";
import "./index.css";
import { BsPersonCircle, BsPersonPlus } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";

export interface HeaderBarProps {
  name?: string;
  hideUserMenu?: boolean;
}

const HeaderBar = (props: HeaderBarProps) => {
  const navigate = useNavigate();
  const { name, hideUserMenu } = props;

  const { isAuthenticating, isAuthenticated, userName, doLogin, doLogout } = useContext(FrontEndContext);

  const authText = isAuthenticating ? "Logging in..." : isAuthenticated ? userName : "Login";
  const logoStyle = { width: 24, height: 24 };
  const authLogo = isAuthenticated ? <BsPersonCircle style={logoStyle} /> : <BsPersonPlus style={logoStyle} />;

  async function doChangePassword() {
    navigate("/change-password");
  }

  async function doGoToHomeScreen() {
    navigate("/");
  }

  const displayUserMenu = () => (
    <Dropdown align="end">
      <Dropdown.Toggle
        id="user-menu-dropdown-toggle"
        style={{
          backgroundColor: "white",
          borderColor: "rgba(0, 0, 0, .15)",
          borderRadius: "0px",
          boxShadow: "none",
          color: "black",
        }}
      >
        <span className="fs-6" style={{ display: "inline-flex", alignItems: "center" }}>
          {authText}&nbsp;&nbsp;{authLogo}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        id="user-menu-dropdown-menu-items"
        style={{ borderRadius: "0px", boxShadow: "5px 5px 5px gray", margin: "0px" }}
      >
        <Dropdown.Item as="button" onClick={doChangePassword}>
          Change Password
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as="button" onClick={doLogout}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const displayNotLoggedInText = () => (
    <div className="header-text auth-text" onClick={() => !isAuthenticating && doLogin()}>
      <span className="align-self-center fs-6">{authText}</span>&nbsp;&nbsp;{authLogo}
    </div>
  );

  const displayRightItems = () => (!isAuthenticated ? displayNotLoggedInText() : displayUserMenu());

  return (
    <div className="header-container">
      <div className="left-items">
        <div>
          <img className="bssi-logo" onClick={doGoToHomeScreen} src={bssiLogo} alt="BSSI logo" />
        </div>
      </div>
      <div className="middle-items">
        <div className="header-text">{name || ""}</div>
      </div>
      <div className="right-items">{!hideUserMenu && displayRightItems()}</div>
    </div>
  );
};

export default HeaderBar;
